<template>
  <div>
    <div class="home">
      <img src="../assets/img/homebg.svg" alt="" class="bg" />
      <div class="floor1">
        <div class="left_box">
          <div class="txt1">
            <span>MIB for SACCOs</span> Cloud-based SACCO Management System
          </div>
          <div class="txt2">
            Manage your SACCO effortlessly <span>easy</span>
          </div>
          <div class="txt3">
            Focus on the main business of your SACCO as we do the heavy-lifting
            for you by automating your processes and offer 24/7 support.
          </div>
          <div class="btn">Get started now</div>
        </div>
        <img src="../assets/img/hero-image.svg" alt="" class="right_img" />
      </div>
      <div class="floor2">
        <div class="list">
          <img src="../assets/img/money.svg" alt="" />
          <div class="title">Affordable</div>
          <div class="txt">Enjoy easy and affordable onboarding procedure.</div>
        </div>
        <div class="list">
          <img src="../assets/img/secure.svg" alt="" />
          <div class="title">Secure</div>
          <div class="txt">
            MIB is a secure platform ensuring your transactions are safe.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/handshake.svg" alt="" />
          <div class="title">Reliable</div>
          <div class="txt">
            With a dedicated team to your SACCO, we ensure no downtime occurs.
          </div>
        </div>
      </div>
      <div class="floor3">
        <div class="left_box">
          <div class="txt1">What is MIB for SACCOs?</div>
          <div class="txt2">
            MIB for SACCOs is a product of MIB African Solutions Limited,a
            technology company with a robust team that builds and supports SACCO
            management systems.<br /><br />
            Our team, with extensive experience in the financial and technology
            industry, has developed a SACCO management system that enables
            SACCOs to achieve their vision, by providing optimization of
            technology at affordable cost.
          </div>
          <div class="btn" @click="GoUrl('/aboutus')">Know more</div>
        </div>
        <img src="../assets/img/why-mib.svg" alt="" class="right_img" />
      </div>
      <div class="floor4">
        <img src="../assets/img/banking-tech.svg" alt="" class="content_img" />
        <img src="../assets/img/f4_bg.svg" alt="" class="bg_img" />
        <div class="floor4_box">
          <div class="title">MIB for SACCOs value proposition</div>
          <div class="txt">
            MIB for SACCOs offers a secure and robust technology backed by a
            dedicated team of professionals to help you focus on the main
            business of your SACCO while we do all the heavy lifting.<br /><br />We
            provide you with tailor-made technology for your SACCO to digitize
            and optimise operations for efficiency and minimize the cost.
          </div>
          <div class="btn" @click="GoUrl('/modules')">Learn more</div>
        </div>
      </div>
      <div class="floor5 clearfix">
        <div class="floor5_box">
          <div class="title">Financial systems integration</div>
          <div class="txt">
            We offer cutting-edge, robust and secure integration with existing
            financial infrastructure and services such as M-Pesa, to ensure fast
            and real-time transactions. <br /><br />
            MIB offers a cloud-based system accessible at anytime on any device.
            Our 24/7 customer support is dedicated to you to ensure you’re
            assisted in case you encounter any hitches.
          </div>
          <div class="btn" @click="GoUrl('/modules')">Learn more</div>
        </div>
        <img
          src="../assets/img/mpesa-integration.svg"
          alt=""
          class="content_img"
        />
        <img src="../assets/img/f5_bg.svg" alt="" class="bg_img" />
      </div>
      <div class="floor6">
        <div class="content_box">
          <div class="title">Our modules</div>
          <div class="list_box mr20">
            <img src="../assets/img/Group2.svg" alt="" />
            <div class="txt">Membership</div>
          </div>
          <div class="list_box">
            <img src="../assets/img/Group3.svg" alt="" />
            <div class="txt">Group Membership</div>
          </div>
          <div class="list_box mr20">
            <img src="../assets/img/notification-module2.svg" alt="" />
            <div class="txt">API Integration</div>
          </div>
          <div class="list_box">
            <img src="../assets/img/notification-module.svg" alt="" />
            <div class="txt">SMS & Email Notification</div>
          </div>
          <div class="btn">Explore more modules</div>
        </div>
        <img src="../assets/img/MaskGroup.svg" alt="" class="hero" />
      </div>
      <div class="floor7">
        <div class="title">Our Clients</div>
        <div class="log_box">
          <img src="../assets/img/Group5.svg" alt="" />
          <img src="../assets/img/Group6.svg" alt="" />
          <img src="../assets/img/Group7.svg" alt="" />
          <img src="../assets/img/Group8.svg" alt="" />
          <img src="../assets/img/Group9.svg" alt="" />
        </div>
      </div>
      <contact></contact>
    </div>
    <div class="h5">
      <img src="@/assets/img/h5/home_bg.svg" alt="" class="bg" />
      <div class="floor1">
        <img src="../assets/img/hero-image.svg" />
        <div class="txt1">
          <span>MIB for SACCOs</span> Cloud-based SACCO Management System
        </div>
        <div class="txt2">Manage your SACCO effortlessly <span>easy</span></div>
        <div class="txt3">
          Focus on the main business of your SACCO as we do the heavy-lifting
          for you by automating your processes and offer 24/7 support.
        </div>
        <div class="btn">Get started now</div>
      </div>
      <div class="floor2">
        <div class="list">
          <img src="../assets/img/money.svg" alt="" />
          <div class="title">Affordable</div>
          <div class="txt">Enjoy easy and affordable onboarding procedure.</div>
        </div>
        <div class="list">
          <img src="../assets/img/secure.svg" alt="" />
          <div class="title">Secure</div>
          <div class="txt">
            MIB is a secure platform ensuring your transactions are safe.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/handshake.svg" alt="" />
          <div class="title">Reliable</div>
          <div class="txt">
            With a dedicated team to your SACCO, we ensure no downtime occurs.
          </div>
        </div>
      </div>
      <div class="floor3">
        <img src="../assets/img/why-mib.svg" alt="" class="right_img" />
        <div class="txt1">What is MIB for SACCOs?</div>
        <div class="txt2">
          MIB for SACCOs is a product of MIB African Solutions Limited,a
          technology company with a robust team that builds and supports SACCO
          management systems.<br /><br />
          Our team, with extensive experience in the financial and technology
          industry, has developed a SACCO management system that enables SACCOs
          to achieve their vision, by providing optimization of technology at
          affordable cost.
        </div>
        <div class="btn" @click="GoUrl('/aboutus')">Know more</div>
      </div>
      <div class="floor4">
        <img src="../assets/img/h5/sacco.svg" alt="" class="content_img" />
        <div class="floor4_box">
          <div class="title">MIB for SACCOs value proposition</div>
          <div class="txt">
            MIB for SACCOs offers a secure and robust technology backed by a
            dedicated team of professionals to help you focus on the main
            business of your SACCO while we do all the heavy lifting.<br /><br />We
            provide you with tailor-made technology for your SACCO to digitize
            and optimise operations for efficiency and minimize the cost.
          </div>
          <div class="btn" @click="GoUrl('/modules')">Learn more</div>
        </div>
      </div>
      <div class="floor4">
        <img src="../assets/img/h5/systems.svg" alt="" class="content_img2" />
        <div class="floor4_box">
          <div class="title">Financial systems integration</div>
          <div class="txt">
            We offer cutting-edge, robust and secure integration with existing
            financial infrastructure and services such as M-Pesa, to ensure fast
            and real-time transactions. <br /><br />
            MIB offers a cloud-based system accessible at anytime on any device.
            Our 24/7 customer support is dedicated to you to ensure you’re
            assisted in case you encounter any hitches.
          </div>
          <div class="btn" @click="GoUrl('/modules')">Learn more</div>
        </div>
      </div>
      <div class="floor6">
        <div class="title">Our modules</div>
        <img src="../assets/img/MaskGroup.svg" alt="" class="hero" />
        <div class="content_box">
          <div class="list_box mr5">
            <img src="../assets/img/Group2.svg" alt="" />
            <div class="txt">Membership</div>
          </div>
          <div class="list_box">
            <img src="../assets/img/Group3.svg" alt="" />
            <div class="txt">Group Membership</div>
          </div>
          <div class="list_box mr5">
            <img src="../assets/img/notification-module2.svg" alt="" />
            <div class="txt">API Integration</div>
          </div>
          <div class="list_box">
            <img src="../assets/img/notification-module.svg" alt="" />
            <div class="txt">SMS & Email Notification</div>
          </div>
          <div class="btn">Explore more modules</div>
        </div>
      </div>
      <div class="floor7">
        <div class="title">Our Clients</div>
        <div class="log_box1">
          <img src="../assets/img/Group5.svg" alt="" />
          <img src="../assets/img/Group6.svg" alt="" />
          <img src="../assets/img/Group7.svg" alt="" />
        </div>
        <div class="log_box2">
          <img src="../assets/img/Group8.svg" alt="" />
          <img src="../assets/img/Group9.svg" alt="" />
        </div>
      </div>
      <contact></contact>
    </div>
  </div>
</template>
<script>
import Contact from "@/components/Contact";
export default {
  name: "Home",
  data() {
    return {};
  },
  created() {
    this.$store.commit("SetActivePage", "home");
  },
  methods: {
    GoUrl(url) {
      this.$router.push(url);
    },
  },
  components: {
    Contact,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .home {
    position: relative;
    width: 100%;
    min-width: 1200px;
    .bg {
      width: 94.3%;
      position: absolute;
      left: 0;
      top: -160px;
      z-index: 1;
    }
    .floor1 {
      position: relative;
      z-index: 2;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 320px;
      .left_box {
        display: inline-block;
        vertical-align: top;
        padding-top: 100px;
        width: 35%;
        margin-right: 20px;
        margin-left: 12%;
        color: #ffffff;
        .txt1 {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 37px;
          span {
            color: #fa945a;
          }
        }
        .txt2 {
          font-style: normal;
          font-weight: 800;
          font-size: 60px;
          line-height: 80px;
          margin: 20px 0;
          span {
            color: #fa945a;
          }
        }
        .txt3 {
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 45px;
          margin-bottom: 35px;
        }
      }
      .right_img {
        display: inline-block;
        vertical-align: top;
        padding-top: 84px;
        width: 51%;
      }
      .btn {
        cursor: pointer;
        display: inline-block;
        background: #fa945a;
        border-radius: 10px;
        height: 64px;
        line-height: 64px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        padding: 0 50px;
      }
    }
    .floor2 {
      display: flex;
      justify-content: space-around;
      margin-bottom: 164px;
      .list {
        background: #ffffff;
        border: 2px solid #622b83;
        box-sizing: border-box;
        border-radius: 20px;
        width: 420px;
        height: 367px;
        padding: 45px 58px 0;
        box-sizing: border-box;
        img {
          display: block;
          margin: 0 auto;
        }
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #622b83;
          margin: 28px 0 34px;
          text-align: center;
        }
        .txt {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 36px;
          color: #622b83;
          text-align: center;
        }
      }
    }
    .floor3 {
      width: 89.58%;
      padding: 136px 0 90px;
      background: #622b83;
      border-radius: 44px;
      margin: 0 auto 78px;
      position: relative;
      .left_box {
        display: inline-block;
        vertical-align: top;
        width: 42%;
        margin-left: 8%;
        .txt1 {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #cc3e91;
        }
        .txt2 {
          font-style: normal;
          font-size: 25px;
          line-height: 40px;
          color: #ffffff;
          margin: 52px 0;
        }
        .btn {
          cursor: pointer;
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 10px;
          height: 64px;
          line-height: 64px;
          width: 290px;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          color: #ffffff;
        }
      }
      .right_img {
        width: 45.23%;
        right: -20px;
        top: -150px;
        position: absolute;
      }
    }
    .floor4 {
      width: 100%;
      position: relative;
      .content_img {
        position: relative;
        z-index: 2;
        width: 38.75%;
        display: inline-block;
        margin: 0 5% 0 4.8%;
        vertical-align: top;
      }
      .bg_img {
        position: absolute;
        left: 0;
        top: 100px;
        width: 42.86%;
        z-index: 1;
      }
      .floor4_box {
        width: 48%;
        display: inline-block;
        vertical-align: top;
        padding-top: 100px;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #622b83;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 40px;
          color: #252424;
          margin: 40px 0;
        }
        .btn {
          background: linear-gradient(
            180deg,
            #fa945a 0%,
            #cc3e91 99.99%,
            #cc3e91 100%
          );
          border-radius: 10px;
          height: 64px;
          line-height: 64px;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          padding: 0 70px;
          color: #ffffff;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
    .floor5 {
      width: 100%;
      position: relative;
      margin-bottom: 400px;
      margin-top: 90px;
      .floor5_box {
        margin-left: 6%;
        width: 45%;
        float: left;
        padding-top: 200px;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #622b83;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 40px;
          color: #252424;
          margin: 40px 0;
        }
        .btn {
          background: linear-gradient(
            180deg,
            #fa945a 0%,
            #cc3e91 99.99%,
            #cc3e91 100%
          );
          border-radius: 10px;
          height: 64px;
          line-height: 64px;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          padding: 0 70px;
          color: #ffffff;
          display: inline-block;
          cursor: pointer;
        }
      }
      .content_img {
        float: right;
        position: relative;
        z-index: 2;
        width: 38.75%;
        margin-right: 8.3%;
      }
      .bg_img {
        position: absolute;
        z-index: 1;
        width: 42.86%;
        right: 0;
        top: 90px;
      }
    }
    .floor6 {
      width: 89.58%;
      padding: 90px 0 60px;
      margin: 0 auto 90px;
      background: #622b83;
      border-radius: 44px;
      position: relative;
      .content_box {
        width: 52.7%;
        margin-left: 8.5%;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #cc3e91;
          margin-bottom: 60px;
        }
        .list_box {
          width: calc(50% - 10px);
          padding-top: 40px;
          height: 204px;
          border: 2px solid #cc3e91;
          box-sizing: border-box;
          border-radius: 20px;
          box-sizing: border-box;
          display: inline-block;
          margin-bottom: 24px;
          vertical-align: top;
          &.mr20 {
            margin-right: 20px;
          }
          img {
            display: block;
            height: 62px;
            margin: 0 auto 24px;
          }
          .txt {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 37px;
            color: #ffffff;
            text-align: center;
          }
        }
        .btn {
          width: 100%;
          height: 64px;
          line-height: 64px;
          text-align: center;
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 10px;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          cursor: pointer;
          color: #ffffff;
        }
      }
      .hero {
        position: absolute;
        width: 35%;
        bottom: 0;
        left: 62.4%;
      }
    }
    .floor7 {
      width: 100%;
      padding: 60px 0 200px;
      background: #efefef;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 37px;
        color: #622b83;
        text-align: center;
        margin-bottom: 160px;
      }
      .log_box {
        display: flex;
        justify-content: space-around;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .home {
    display: none;
  }
  .h5 {
    width: 100%;
    .bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .floor1 {
      position: relative;
      z-index: 2;
      width: 100%;
      box-sizing: border-box;
      color: #ffffff;
      img {
        display: block;
        width: 94%;
        margin: 0 auto 10px;
      }
      .txt1 {
        width: 80%;
        margin: 0 auto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
        span {
          color: #fa945a;
        }
      }
      .txt2 {
        width: 80%;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        margin: 12px auto;
        span {
          color: #fa945a;
        }
      }
      .txt3 {
        width: 80%;
        margin: 0 auto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 0 auto 16px;
      }
      .btn {
        cursor: pointer;
        display: inline-block;
        background: #fa945a;
        border-radius: 5px;
        height: 32px;
        line-height: 32px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 0 18px;
        margin-left: 10%;
      }
    }
    .floor2 {
      width: 100%;
      padding: 130px 4% 50px;
      box-sizing: border-box;
      .list {
        width: 100%;
        height: 190px;
        padding-top: 10px;
        background: #ffffff;
        border: 1px solid #622b83;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 10px;
        img {
          height: 42px;
          display: block;
          margin: 0 auto;
        }
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          color: #622b83;
          margin: 14px 0 16px;
          text-align: center;
        }
        .txt {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #622b83;
          text-align: center;
          width: 60%;
          margin: 0 auto;
        }
      }
    }
    .floor3 {
      width: calc(100% - 48px);
      position: relative;
      background: #622b83;
      border-radius: 22px;
      margin: 20px auto 0;
      box-sizing: border-box;
      padding: 40px 16px 30px;
      .right_img {
        position: absolute;
        width: 170px;
        height: 170px;
        right: 0;
        top: -80px;
      }
      .txt1 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #cc3e91;
        width: calc(100% - 170px);
      }
      .txt2 {
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        margin: 26px 0;
      }
      .btn {
        cursor: pointer;
        background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
        border-radius: 5px;
        height: 32px;
        line-height: 32px;
        width: 195px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .floor4 {
      padding-top: 20px;
      .content_img {
        display: block;
        width: 80%;
      }
      .content_img2 {
        display: block;
        width: 80%;
        margin-left: 20%;
      }
      .floor4_box {
        width: 92%;
        margin: 0 auto;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: #622b83;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #252424;
          margin: 20px 0;
        }
        .btn {
          background: linear-gradient(
            180deg,
            #fa945a 0%,
            #cc3e91 99.99%,
            #cc3e91 100%
          );
          border-radius: 5px;
          height: 32px;
          line-height: 32px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          padding: 0 35px;
          color: #ffffff;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
    .floor6 {
      width: 94%;
      margin: 0 auto;
      background: #622b83;
      border-radius: 22px;
      padding: 40px 16px 30px;
      position: relative;
      margin-top: 50px;
      box-sizing: border-box;
      .title {
        color: #cc3e91;
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 24px;
      }
      .hero {
        position: absolute;
        right: 10px;
        top: -50px;
        width: 111px;
        height: 111px;
      }
      .content_box {
        margin-top: 24px;
        .list_box {
          display: inline-block;
          vertical-align: top;
          margin-bottom: 12px;
          height: 100px;
          width: calc(50% - 5px);
          padding-top: 20px;
          border: 1px solid #cc3e91;
          box-sizing: border-box;
          border-radius: 10px;
          &.mr5 {
            margin-right: 5px;
          }
          img {
            height: 30px;
            display: block;
            margin: 0 auto 8px;
          }
          .txt {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            text-align: center;
          }
        }
        .btn {
          width: 100%;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
    .floor7 {
      margin: 40px 0;
      width: 100%;
      background: #efefef;
      padding: 80px 70px;
      box-sizing: border-box;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #622b83;
        text-align: center;
      }
      .log_box1 {
        margin: 50px 0 30px;
        display: flex;
        justify-content: space-between;
        img {
          width: 52px;
        }
      }
      .log_box2 {
        display: flex;
        justify-content: space-around;
        img {
          width: 52px;
        }
      }
    }
  }
}
</style>
