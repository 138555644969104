<template>
  <div>
    <div class="contact_box">
      <div class="contain">
        <div class="left_box">
          <div class="title">Contact us</div>
          <div class="txt">
            Are you in need of automizing your SACCO today? Drop your details
            here and we’ll contact you.
          </div>
          <a href="tel:+254 706 169 317" class="tel">+254 706 169 317</a>
          <a href="mailto:sacco@mibafrica.com" class="email"
            >sacco@mibafrica.com</a
          >
        </div>
        <div class="right_box">
          <div class="title">Contact Form</div>
          <div class="list_from">
            <div class="label">Full name</div>
            <input type="text" v-model="fullName" />
          </div>
          <div class="list_from">
            <div class="label">Email</div>
            <input type="text" v-model="email" />
          </div>
          <div class="list_from">
            <div class="label">Phone number</div>
            <input type="text" v-model="mobile" />
          </div>
          <div class="list_from">
            <div class="label">Your message</div>
            <textarea v-model="message" cols="30" rows="6"></textarea>
          </div>
          <div class="btn" @click="Send">Send now</div>
        </div>
      </div>
    </div>
    <div class="h5">
      <div class="contain">
        <div class="left_box">
          <div class="title">Contact us</div>
          <div class="txt">
            Are you in need of automizing your SACCO today? Drop your details
            here and we’ll contact you.
          </div>
          <a href="tel:+254 706 169 317" class="tel">+254 706 169 317</a>
          <a href="mailto:sacco@mibafrica.com" class="email"
            >sacco@mibafrica.com</a
          >
        </div>
        <div class="right_box">
          <div class="title">Contact Form</div>
          <div class="list_from">
            <div class="label">Full name</div>
            <input type="text" v-model="fullName" />
          </div>
          <div class="list_from">
            <div class="label">Email</div>
            <input type="text" v-model="email" />
          </div>
          <div class="list_from">
            <div class="label">Phone number</div>
            <input type="text" v-model="mobile" />
          </div>
          <div class="list_from">
            <div class="label">Your message</div>
            <textarea v-model="message" cols="30" rows="6"></textarea>
          </div>
          <div class="btn" @click="Send">Send now</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/http/axios";
export default {
  data() {
    return {
      fullName: "",
      email: "",
      mobile: "",
      message: "",
    };
  },
  methods: {
    Send() {
      let postData = {
        firstName: this.fullName,
        message: this.message,
        phoneNumber: this.mobile,
        email: this.email,
        sourceFrom: "sacc",
      };
      if (postData.firstName == "") {
        this.$ftoast({
          text: "Please enter the Full Name",
        });
        return false;
      }
      if (!/^(01|07|1|7)\d{8}/.test(this.phoneNumber)) {
        this.$ftoast({
          text: "Please enter the correct number",
        });
        return false;
      }
      axios.post("/application/contact", postData).then((res) => {
        console.log(res);
        this.$ftoast({
          text: "Submit successfully",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .contact_box {
    padding: 200px 5%;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    .contain {
      width: 100%;
      .left_box {
        width: 50%;
        height: 1027px;
        padding: 80px 5%;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        background-color: #622b83;
        border-radius: 44px 0 0 44px;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #cc3e91;
          margin-bottom: 100px;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 45px;
          line-height: 70px;
          color: #fff;
          padding-bottom: 70px;
          border-bottom: 3px solid #fefefe;
          margin-bottom: 90px;
        }
        .tel {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 56px;
          height: 56px;
          color: #fff;
          cursor: pointer;
          padding-left: 54px;
          position: relative;
          text-decoration: none;
          background-image: url("../assets/img/mobile.svg");
          background-repeat: no-repeat;
          background-size: 36px 36px;
          background-position: left 9px;
          margin-bottom: 20px;
        }
        .email {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 56px;
          height: 56px;
          color: #fff;
          cursor: pointer;
          padding-left: 54px;
          position: relative;
          text-decoration: none;
          background-image: url("../assets/img/email.svg");
          background-repeat: no-repeat;
          background-size: 36px 36px;
          background-position: left 9px;
        }
      }
      .right_box {
        width: 50%;
        height: 1027px;
        padding: 80px 5%;
        box-sizing: border-box;
        background-color: #f5f7e9;
        display: inline-block;
        vertical-align: top;
        border-radius: 0 44px 44px 0;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 35px;
          line-height: 37px;
          text-align: center;
          color: #4d4d4d;
          margin-bottom: 70px;
        }
        .list_from {
          margin-bottom: 50px;
          border-bottom: 1px solid #4d4d4d;
          .label {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: #a6a4a4;
            margin-bottom: 8px;
          }
          input {
            height: 56px;
            line-height: 56px;
            background-color: transparent;
            outline: none;
            border: none;
            display: block;
            font-weight: 600;
            font-size: 25px;
            color: #4d4d4d;
          }
          textarea {
            background-color: transparent;
            outline: none;
            border: none;
            display: block;
            font-weight: 600;
            font-size: 25px;
            color: #4d4d4d;
            line-height: 32px;
          }
        }
        .btn {
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 10px;
          width: 100%;
          height: 64px;
          line-height: 64px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .contact_box {
    display: none;
  }
  .h5 {
    .contain {
      position: relative;
      width: 100%;
      padding-bottom: 500px;
      .left_box {
        position: relative;
        background: #622b83;
        padding: 0 3%;
        width: 100%;
        height: 390px;
        box-sizing: border-box;
        z-index: 1;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: #cc3e91;
          text-align: center;
          padding: 40px 0 30px;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 34px;
          color: #fff;
          padding-bottom: 20px;
          border-bottom: 1px solid #fefefe;
          margin-bottom: 24px;
        }
        .tel {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          height: 28px;
          color: #fff;
          cursor: pointer;
          padding-left: 27px;
          position: relative;
          text-decoration: none;
          background-image: url("../assets/img/mobile.svg");
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-position: left 5px;
          margin-bottom: 10px;
        }
        .email {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          height: 28px;
          color: #fff;
          cursor: pointer;
          padding-left: 27px;
          position: relative;
          text-decoration: none;
          background-image: url("../assets/img/email.svg");
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-position: left 5px;
        }
      }
      .right_box {
        position: absolute;
        left: 3%;
        right: 3%;
        background: #f5f7e9;
        border-radius: 22px;
        top: 340px;
        z-index: 20;
        padding: 0 3% 20px;
        box-sizing: border-box;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          color: #4d4d4d;
          padding: 40px 0 30px;
        }
        .list_from {
          margin-bottom: 20px;
          border-bottom: 1px solid #4d4d4d;
          .label {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #a6a4a4;
            margin-bottom: 4px;
          }
          input {
            height: 28px;
            line-height: 28px;
            background-color: transparent;
            outline: none;
            border: none;
            display: block;
            font-weight: 600;
            font-size: 14px;
            color: #4d4d4d;
          }
          textarea {
            background-color: transparent;
            outline: none;
            border: none;
            display: block;
            font-weight: 600;
            font-size: 14px;
            color: #4d4d4d;
            line-height: 18px;
          }
        }
        .btn {
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 10px;
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
